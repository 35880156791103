import ApiService from './api.service';
import { PATIENT_SIGNATURE_TYPE } from '../enums';

export default {
  buildPath: path => `${window.ambulnzConfig.tenantedRestApiUrl}/${path}`,

  async handleCreate(requestId, attachment, actorId, actorTypeId) {
    return attachment.attachmentType.type === PATIENT_SIGNATURE_TYPE
      ? this.createSignature(requestId, attachment, actorId, actorTypeId)
      : this.createRequestAttachment(requestId, attachment);
  },

  async createRequestAttachment(requestId, attachment) {
    const url = this.buildPath(`Requests/${requestId}/attachment?attachmentTypeId=${attachment.type}`);

    // eslint-disable-next-line
    const response = await ApiService.postFile(
      url,
      attachment.formData._blob ? attachment.formData._blob() : attachment.formData,
    ).then(resp => resp.json());
    return response.request;
  },

  async createSignature(requestId, attachment, actorId, actorTypeId) {
    const signaturesUrl = this.buildPath(`Requests/${requestId}/Signatures`);
    const signature = await ApiService.post(signaturesUrl, {
      actorTypeId,
      actorId,
      attachmentTypeId: attachment.type,
    }).then(resp => resp.json());
    const url = this.buildPath(
      `Requests/${requestId}/Signatures/${signature.id}/Attachment?originalFilename=${attachment.name}`,
    );
    const result = await ApiService.postFile(url, attachment.formData).then(resp => resp.json());
    return this.presentAsResource(result);
  },

  async createStandaloneAttachment(attachment) {
    const url = this.buildPath(
      `Attachments/?fileOptions=${encodeURIComponent(JSON.stringify({ originalFileName: attachment.name }))}`,
    );
    const response = await ApiService.postFile(url, attachment).then(resp => resp.json());
    return response;
  },

  async createAttachments(requestId, attachments, actorId, actorTypeId) {
    const promises = attachments.map(attachment => this.handleCreate(requestId, attachment, actorId, actorTypeId));

    const result = await Promise.all(promises).catch(e => {
      throw e;
    });
    return result.map(res => res);
  },

  async getAttachments(requestId) {
    const url = this.buildPath(`Requests/${requestId}/attachments`);
    const response = await ApiService.get(url);
    return response.json();
  },

  async getSignatures(requestId) {
    const url = this.buildPath(`Requests/${requestId}/Signatures`);
    const response = await ApiService.get(url);
    return response.json();
  },

  async deleteAttachment(requestAttachmentId) {
    const url = this.buildPath(`RequestAttachment/${requestAttachmentId}`);
    return ApiService.delete(url);
  },

  async deleteSignature(requestAttachmentId) {
    const url = this.buildPath(`RequestSignatures/${requestAttachmentId}`);
    return ApiService.delete(url);
  },

  async deleteResource(resourceId) {
    const url = this.buildPath(`Resources/${resourceId}`);
    const response = await ApiService.delete(url);
    return response.json();
  },

  async getRequestAttachmentTypes() {
    const url = this.buildPath(`RequestAttachmentTypes?includeSignatures=true`);
    const response = await ApiService.get(url);
    return response.json();
  },

  async getRequestSignatureActorTypes() {
    const url = this.buildPath(`RequestSignatureActorTypes`);
    const response = await ApiService.get(url);
    return response.json();
  },

  presentAsResource(requestSignature, requestId) {
    const { attachment, attachmentType } = requestSignature;
    return {
      ...attachment,
      entityType: 'Request',
      entityId: requestId,
      name: attachment.name,
      requestAttachmentId: null,
      requestSignatureId: requestSignature.id,
      attachmentType,
    };
  },
};

/* global google */
import React from 'react';
import PropTypes from 'prop-types';
import { MarkerF, OverlayView, OverlayViewF } from '@react-google-maps/api';
import Done from '../common/icons/done';
import icon from '../common/icons/map-pin.png';
import { timeFormat } from '../../helpers/dateFormatter';

const PickupMarker = ({ pickupTime, position, complete, isNonTransport }) => {
  const image = {
    url: icon,
    size: new google.maps.Size(82, 92),
    origin: new google.maps.Point(0, 0),
    anchor: new google.maps.Point(41, 77),
  };

  const getPixelPositionOffset = (offsetWidth, offsetHeight, labelAnchor) => {
    return {
      x: offsetWidth + labelAnchor.x,
      y: offsetHeight + labelAnchor.y,
    };
  };

  const pickup = (
    <div
      style={{
        width: 82,
        height: 92,
      }}
    >
      <p
        style={{
          margin: 0,
          color: '#fff',
          fontSize: window.ambulnzConfig.userSettings.ampm ? 11 : 13,
          fontWeight: 500,
          textAlign: 'center',
        }}
      >
        {pickupTime ? timeFormat(pickupTime) : isNonTransport ? 'Service' : 'Pickup'}
      </p>
    </div>
  );

  const done = (
    <Done
      style={{
        fill: '#fff',
        width: 40,
        height: 40,
        position: 'relative',
        top: -34,
        left: 0,
      }}
    />
  );

  const content = complete ? done : pickup;

  return (
    <>
      <MarkerF key="m" position={position} icon={image}></MarkerF>
      <OverlayViewF
        key="mwl"
        position={position}
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        getPixelPositionOffset={(x, y) => getPixelPositionOffset(-(x / 2), -(y / 2), { x: 0, y: -12 })}
      >
        <div id="pickup-marker-overlay">{content}</div>
      </OverlayViewF>
    </>
  );
};

PickupMarker.propTypes = {
  position: PropTypes.shape().isRequired,
  complete: PropTypes.bool.isRequired,
  pickupTime: PropTypes.string,
};

PickupMarker.defaultProps = {
  pickupTime: '',
};

export default PickupMarker;

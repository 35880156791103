import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AmbGrid from '../../common/AmbGrid';
import * as etaActions from '../../../redux/actions/eta.actions';
import * as willCallActions from '../../../redux/actions/willCall.actions';
import GetETAButton from './GetETAButton.frame';
import { openMedicalNecessityModal } from '../../MedicalNecessityStepper/redux/openMedicalNecessityModal';
import { needsMedicalNecessityQuestionnaire } from '../../../flags/mnq.flag';

function GetEtaButtonContainer(props) {
  const handleGetEtaClick = () => {
    setTimeout(
      () => (props.needsMedicalNecessityQuestionnaire ? props.openMedicalNecessityModal(true) : props.clickGetETA()),
      100,
    );
  };

  return (
    <AmbGrid style={{ alignSelf: 'center' }}>
      <GetETAButton {...props} handleGetEtaClick={handleGetEtaClick} />
    </AmbGrid>
  );
}

function mapStateToProps(state) {
  return {
    isDisabled: state.eta.isDisabled || !state.app.online,
    loadedWillCall: state.requests.loadedWillCall,
    isVisible: !(state.eta.pollInProgress || state.formFlow.acceptETAInProgress),
    needsMedicalNecessityQuestionnaire: needsMedicalNecessityQuestionnaire(state),
    isNonTransport: state.requests.isNonTransport,
  };
}

GetEtaButtonContainer.propTypes = {
  needsMedicalNecessityQuestionnaire: PropTypes.bool.isRequired,
  openMedicalNecessityModal: PropTypes.func.isRequired,
  clickGetETA: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
  ...etaActions,
  ...willCallActions,
  openMedicalNecessityModal,
})(GetEtaButtonContainer);
